/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {VideoIframe, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "hearing-aids-retention-wires--what-they-are-and-how-to-insert",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-retention-wires--what-they-are-and-how-to-insert",
    "aria-label": "hearing aids retention wires  what they are and how to insert permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids retention wires – What they are and how to insert"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Living with hearing loss can present daily challenges, but modern technology offers solutions like hearing aids to improve quality of life. Among the various components of hearing aids, retention wires play a crucial role in ensuring proper fit and functionality. In this guide, we'll delve into what retention wires are, their importance, and how to correctly use them with your hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-retention-wires",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-retention-wires",
    "aria-label": "what are retention wires permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Are Retention Wires?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Retention wires, also known as retention clips or ear hooks, are small, flexible wires typically made of durable materials like silicone or plastic. These wires attach to the hearing aid and loop around the back of the ear, securing the device in place. Their primary purpose is to prevent the hearing aid from falling out or becoming dislodged during daily activities."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "importance-of-retention-wires-or-locks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#importance-of-retention-wires-or-locks",
    "aria-label": "importance of retention wires or locks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Importance of Retention Wires or Locks:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For individuals with hearing loss, maintaining consistent access to sound is paramount. Retention wires provide several benefits:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Secure Fit: They ensure the hearing aid stays snugly in place, reducing the risk of accidental displacement."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Comfort: Properly fitted retention wires distribute the weight of the hearing aid evenly, minimizing discomfort during prolonged wear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Stability: By anchoring the device behind the ear, retention wires enhance stability, allowing users to engage in various activities with confidence."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Prevention of Loss or Damage: The secure attachment offered by retention wires reduces the likelihood of losing or damaging the hearing aid, saving both time and money on repairs or replacements."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-put-on-retention-wires",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-put-on-retention-wires",
    "aria-label": "how to put on retention wires permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Put on Retention Wires:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Follow these simple steps to correctly attach retention wires to your hearing aids:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Identify the Right Wire: Ensure that you have the appropriate retention wire compatible with your hearing aid model. These wires often come in various sizes and shapes to accommodate different ear and hearing aid designs."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Positioning: Hold the hearing aid with the speaker (receiver) facing towards your ear canal. The wire should extend from the bottom of the hearing aid."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Loop Around the Ear: With the hearing aid in position, gently loop the retention wire around the back of your ear. Adjust the wire for a comfortable fit, ensuring it's neither too tight nor too loose."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Check Fit: Once the retention wire is in place, verify that the hearing aid sits securely and comfortably in your ear. You should be able to move your head without the device feeling unstable."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Test Functionality: After attaching the retention wire, test the functionality of your hearing aid to ensure that sound transmission remains clear and uninterrupted."), "\n"), "\n", React.createElement(VideoIframe, {
    title: "Hear.com Tips and Tricks | How to Add a Retention Wire",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/NMxJFWxDqLo?si=nfDBrxIbp_gD2I4S"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-specialist-from-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-specialist-from-hearcom",
    "aria-label": "contact a hearing specialist from hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hearing specialist from hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Retaining proper fit and functionality of your hearing aids is essential for individuals with hearing loss to navigate daily life with confidence. Understanding the role of retention wires and how to correctly use them ensures that your hearing aids stay securely in place, allowing you to fully benefit from their capabilities. By following the steps outlined in this guide, you can enjoy improved comfort, stability, and reliability in your hearing aid experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ready to explore upgrading your hearing aids for even better sound quality and features? Contact a hearing specialist from ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " today. Our team is dedicated to guiding you through the process of finding the perfect hearing solution tailored to your needs, so you can enjoy a richer, fuller hearing experience. Take the first step towards better hearing today."), "\n", React.createElement(LandingPageCta, {
    copy: "Start your 45-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
